import React from "react";

const YouTubeEmbed = ({ videoId }) => {
  return (
    <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          border: "0.5px solid #362271",
          borderRadius: "16px",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.3)",
        }}
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
