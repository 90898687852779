import React, { useEffect, useState } from "react";
import TeepeeLogo from "../images/logo/teepee_logo.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiConfig from "../config/apiConfig";
import QuizGenerationLoading from "../Components/GenerateQuiz/QuizGenerationLoading";
import LoadingIndicator from "../Components/LoadingIndicator";
import Footer from "../Components/Footer";

const TopicSelection = () => {
  const [selectedTopics, setSelectedTopics] = useState(new Set());
  const subject_id = window.sessionStorage.getItem("subject_id");
  const [topics, setTopic] = useState([]);
  const [subtopics, setSubtopics] = useState({}); // Store but don't display
  const [isLoading, setIsLoading] = useState(false);
  const [istopicLoad, setIsTopicLoad] = useState(true);
  const navigate = useNavigate();

  const getListOfChapter = () => {
    let mounted = true;
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_chapters",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: subject_id,
          selected_quiz_type: 3,
        }),
      })
      .then((res) => {
        if (mounted) {
          setTopic(res.data);
          setIsTopicLoad(false);
        }
      });

    return () => (mounted = false);
  };

  useEffect(() => {
    getListOfChapter();
  }, []);

  const handleTopicSelect = async (topic) => {
    const newSelectedTopics = new Set(selectedTopics);

    if (newSelectedTopics.has(topic)) {
      // Remove topic and its subtopics
      newSelectedTopics.delete(topic);
      const newSubtopics = { ...subtopics };
      delete newSubtopics[topic.chapter_id];
      setSubtopics(newSubtopics);
    } else {
      // Add topic and fetch its subtopics
      newSelectedTopics.add(topic);

      await fetchSubtopics(topic);
    }
    setSelectedTopics(newSelectedTopics);
  };

  const fetchSubtopics = async (topic) => {
    try {
      const response = await axios.request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topics_tool",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          chapterId: topic.chapter_id,
          chapterName: topic.chapter_name,
          selected_quiz_type: 3,
        }),
      });

      setSubtopics((prev) => ({
        ...prev,
        [topic.chapter_id]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching subtopics:", error);
    }
  };

  const generateQuiz = (selectedSubtopicIds) => {
    setIsLoading(true);
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + apiConfig.endpoints.generateQuiz, {
        method: "POST",
        subject_id: subject_id,
        sub_topic_id_list: selectedSubtopicIds,
        number_of_questions: 10,
        quiz_type: 3,
        question_ids: [],
        quiz_status: 0,
        exam_paper_quiz_status: 0,
        category: null,
        account_status: null,
        is_task: false,
        code_status: 0,
        web_user_id: process.env.REACT_APP_WEB_USER_ID,
      })

      .then((response) => {
        setIsLoading(false);
        navigate("/quiz_generation", {
          state: {
            quiz_data: response.data,
            topic_id: [...selectedTopics].map((topic) => topic.chapter_id),
            subtopic_ids: selectedSubtopicIds,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNext = () => {
    const allSelectedSubtopics = Array.from(selectedTopics).reduce((acc, topic) => {
      return acc.concat(subtopics[topic.chapter_id] || []);
    }, []);
    const selectedSubtopicIds = allSelectedSubtopics.map((value) => value.topic_id);

    generateQuiz(selectedSubtopicIds);
  };

  return (
    <div>
      <div className="min-h-screen bg-white flex flex-col">
        <div className="pt-6 pl-20 inline-block w-fit" style={{ display: isLoading && "none" }}>
          <img onClick={() => navigate("/")} className="w-[56vw] md:w-[15vw] cursor-pointer" src={TeepeeLogo} alt="logo" />
          <div className="flex justify-end cursor-pointer" onClick={() => window.open("https://teepee.ai", "_blank")}>
            <div className="mr-1 text-[12px] flex items-center">by</div>
            <div>Teepee.ai</div>
          </div>
        </div>

        <div className="flex-grow">
          {istopicLoad && (
            <div>
              <LoadingIndicator />
            </div>
          )}

          {isLoading ? (
            <QuizGenerationLoading />
          ) : (
            !istopicLoad && (
              <div className="max-w-6xl mx-auto p-8">
                {/* Main Content */}
                <div className="mb-12  md:flex ">
                  <h1 className="text-[24px] font-bold text-gray-800 mb-1">{`Select a topic to create your Topic-Focused Practice Exam.`}</h1>
                  <p className="text-sm text-gray-500 md:ml-1">(Minimum: 01 Topic)</p>
                </div>

                {/* Topics Grid */}
                <div className="flex flex-wrap gap-3 mb-12">
                  {topics.map((topic, index) => (
                    <button
                      key={index}
                      onClick={() => handleTopicSelect(topic)}
                      className={`
                      border-[1px]
                      change-border
                px-4 py-2 rounded-full  
                ${selectedTopics.has(topic) ? "bg-[#6750A4] text-white hover:text-green-500                " : "bg-white text-[#6750A4] "}
                hover:bg-[#ADA0F4] hover:text-grey transition-colors duration-200
                text-sm font-bold
              `}
                    >
                      {topic.chapter_name}
                    </button>
                  ))}
                </div>

                {/* Navigation Buttons */}
                <div className="grid md:flex md:justify-evenly">
                  <button
                    className="mb-2 md:mb-0 md:w-[30%] px-14 py-2 border-[1px]
                      change-border rounded text-[#6750A4] hover:bg-gray-50"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className={`md:w-[30%]
              px-14 py-2 rounded 
              ${selectedTopics.size > 0 ? "bg-[#6750A4] text-white hover:bg-gray-700" : "bg-[#CACACC] text-gray-500 cursor-not-allowed"}
            `}
                    disabled={selectedTopics.size === 0}
                    onClick={handleNext}
                  >
                    Generate Exam Questions
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TopicSelection;
