import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TeacherDetailsDialogue from "../Components/GenerateQuiz/TeacherDetailsDialogue";
import apiConfig from "../config/apiConfig";
import axios from "axios";
import ShareQuizDialog from "../Components/GenerateQuiz/ShareQuizDialog";
import TeepeeLogo from "../images/logo/teepee_logo.svg";
import Footer from "../Components/Footer";
import TextCopyAcknowledgement from "../Components/TextCopyAcknowledgement";
import CodeEditor from "../Components/Quiz/CodeEditor";

const GenerateQuiz = () => {
  const location = useLocation();
  const [quizData, setQuizData] = useState(JSON.parse(location.state.quiz_data?.question_answers || "[]"));
  const { topic_id } = location.state;
  const { subtopic_ids } = location.state;
  const [isOpenTeacherDetailDialog, setIsOpenTeacherDetailDialog] = useState(false);
  const [isOpenShareQuizDialog, setIsOpenShareQuizDialog] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [acknowledgePopup, setAcknowledgePopup] = useState(false);
  const navigate = useNavigate();

  function removeHtmlTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in the string
    return str.replace(/<p>/g, "");
  }

  const handleOpenShareQuizConfirmationDialogue = () => {
    setIsOpenTeacherDetailDialog(true);
  };

  const onSubmitTeacherDetailsDialog = (teacherName, teacherEmail, quizName) => {
    setIsSubmit(true);
    let dt = {
      quiz_name: quizName,
      topic_id: subtopic_ids[0],
      question_answers: quizData,
      sub_topic_ids_csv: subtopic_ids.join(","),
      creation_tokens_count: 0,
      quiz_generation_ids_list: [],
      sso_required: false,
      quiz_type: 3,
      folder_id: null,
      class_id: null,
      quiz_status: null,
      quiz_instant_feedback_status: false,
      quiz_duration: 0,
      quiz_grace_time: 0,
      quiz_auto_submission: 0,
      shuffle_quiz_questions: 0,
      subject_id: window.sessionStorage.getItem("subject_id"),
      account_status: null,
      teacher_name: teacherName,
      email: teacherEmail,
      web_user_id: process.env.REACT_APP_WEB_USER_ID,
    };
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + apiConfig.endpoints.saveQuiz, {
        method: "POST",
        body: dt,
      })

      .then((response) => {
        console.log(response);
        setSecretKey(response.data.secret_key);
        setIsOpenTeacherDetailDialog(false);
        setIsOpenShareQuizDialog(true);
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClose = () => {
    setIsOpenShareQuizDialog(false);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...quizData];
    if (index >= 0 && index < updatedQuestions.length) {
      updatedQuestions.splice(index, 1); // Remove the question
      setQuizData(updatedQuestions); // Update the state to trigger re-render
    }
  };

  function removeHtmlTags2(str) {
    if (str === null || str === "") return "";
    str = str.toString();

    // Step 1: Replace <br> and <p> with newlines to preserve line breaks
    str = str.replace(/<br\s*\/?>|<\/p>/g, "\n").replace(/<p[^>]*>/g, "");

    // Remove other HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Wrap text between ##code markers in <pre><code> tags
    str = str.replace(/##code([\s\S]*?)##code/g, "<pre><code>$1</code></pre>");

    return str;
  }

  return (
    <div>
      {acknowledgePopup && <TextCopyAcknowledgement />}
      <div className="mb-2 pt-6 pl-20 inline-block w-fit">
        <img onClick={() => navigate("/")} className="w-[56vw] md:w-[15vw] cursor-pointer" src={TeepeeLogo} alt="logo" />
        <div className="flex justify-end cursor-pointer" onClick={() => window.open("https://teepee.ai", "_blank")}>
          <div className="mr-1 text-[12px] flex items-center">by</div>
          <div>Teepee.ai</div>
        </div>
      </div>
      <hr className="mt-0"></hr>
      {isOpenTeacherDetailDialog && <TeacherDetailsDialogue onSubmitTeacherDetailsDialog={onSubmitTeacherDetailsDialog} isSubmit={isSubmit} />}
      {isOpenShareQuizDialog && (
        <ShareQuizDialog secretKey={secretKey} onClose={onClose} setAcknowledgePopup={setAcknowledgePopup} acknowledgePopup={acknowledgePopup} />
      )}
      <p
        style={{
          marginBottom: "10px",
          fontFamily: "Roboto",
          textAlign: "center",
          marginTop: "10px",
          fontSize: "36px",
          fontWeight: 600,
        }}
      >
        {"There you go 👇"}
      </p>
      <div className="flex justify-center mb-[50px] text-center">
        Review the questions below and share them with your students. <br />
        They’ll receive instant AI marking and feedback after attempting them.
      </div>

      <div className="flex justify-center">
        <div className=" w-[95%] md:w-[50%]">
          {quizData.map((rowData, index) => (
            <Fragment key={index}>
              <div>
                <div>
                  <div>
                    <div className="md:w-auto ">
                      <div
                        style={{
                          padding: "10px ",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "10px",
                          backgroundColor: "#F0F0F0",
                        }}
                      >
                        <p
                          className="answer-paragraph !whitespace-normal	justify-between !mb-0"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                            display: "flex",
                          }}
                        >
                          <span className="w-[75%] md:w-[85%]">
                            <b>Question {index + 1}: </b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHtmlTags(rowData.question?.split("##code")[0]),
                              }}
                            ></span>
                          </span>

                          <b className="md:w-auto font-bold flex justify-end whitespace-nowrap"> [Marks: {rowData.question_marks}]</b>
                        </p>
                        {rowData.image_file_name !== null && (
                          <div className="flex justify-center mb-[20px] mt-[20px]">
                            <img alt="" className="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}></img>
                          </div>
                        )}
                        {rowData.have_code === 1 && <CodeEditor readOnly={true} value={removeHtmlTags2(rowData.question?.split("##code")[1])} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="close border-[1px]  px-4 mb-12"
                  aria-label="Close"
                  onClick={() => removeQuestion(index)}
                  value={index}
                  style={{
                    height: "50px",
                    fontSize: "16px",
                    borderRadius: "5px",
                  }}
                >
                  <span className="pr-[10px]" aria-hidden="true">
                    &times;
                  </span>
                  Remove
                </button>
              </div>
            </Fragment>
          ))}
          <button
            style={{}}
            value={1}
            onClick={(e) => handleOpenShareQuizConfirmationDialogue(e.target.value)}
            //   disabled={isSubmittingShare}
            className="teepee-button ml-12 hover:bg-black w-[full]  w-[100%] mt-6 mb-12"
          >
            Let’s Share with Students
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default GenerateQuiz;
