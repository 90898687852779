import React, { Fragment, useEffect, useState } from "react";
import CustomizedAccordions from "./Accordian";
import AnswerAccordian from "./AnswerAccordian";
import LikeDislike from "./LikeDislike";
import axios from "axios";
import ShimmerEffect from "./ShimmerEffect";
import { useLocation } from "react-router-dom";
import RecheckSidePanel from "./RecheckDialog";
import EdiText from "react-editext";
import CodeEditor from "./CodeEditor";
import FeedbackDisplay from "./FeedbackDisplay";

function QuizReviewCard(props) {
  const location = useLocation();
  const quizData = props.quizData;
  const quizType = props.quizType;

  const mock_exam = props.mock_exam;
  const show_teacher_feedback = props.show_teacher_feedback;
  const quiz_instant_feedback_status = props.quiz_instant_feedback_status;
  const quiz_response_id = props.quiz_response_id;
  const spinnerVisibility = props.spinnerVisibility;
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [thumbs_up_down, setThumbs_up_down] = useState();
  const auth_type = localStorage.getItem("user_type");
  const [totalQuestions, setTotalQuestions] = useState();
  const [selectedQuizQuestion, setSelectedQuizQuestion] = useState([]);
  const [open, setOpen] = useState(false);
  let q_index = 0;

  const getQuizResponseByQuestionContextId = (question_context_id, index) => {
    let question_answers_array = [];
    for (let quiz_response in quizData) {
      let quiz_question = JSON.parse(quizData[quiz_response].quiz_question);
      if (quiz_question.question_context_id === question_context_id) {
        question_answers_array.push(quizData[quiz_response]);
      }
    }
    console.log("TEACHER: ", question_answers_array[index]);
    return question_answers_array[index];
  };
  const handleOpen = (index, quiz) => {
    console.log("here", quiz[index].recheck_data);
    setThumbs_up_down(quiz[index].recheck_data.thumbs_up_down);

    setSelectedQuizQuestion(quiz);
    setTotalQuestions(quiz.length);
    setSelectedQuestion(index);

    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
      // setOpen(true);
      setTimeout(() => {
        setOpen(true); // Close the sidebar after 1 second
      }, 500); // 1000 milliseconds = 1 second
    }
  };

  // Method to close the dialog
  const handleClose = () => {
    setOpen(false);
  };
  const onSaveFeedback = (feedback, question) => {
    if (feedback !== question.feedback) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_question_feedback", {
          method: "POST",
          question_response_id: question.question_response_id,
          feedback: feedback,
        })

        .then((response) => {
          console.log("RESPONSE OF EDIT FEEDBACK: ", response);
          props.getQuizReviewData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onSaveMarks = (marks, question) => {
    // console.log("called", marks, question)
    // if(marks > question.question_marks){
    //   alert("please edit marks less than the total marks")
    //   return;
    // }

    if (marks.toString() !== question.score.toString()) {
      var token = "Bearer " + localStorage.getItem("access_token");
      console.log("data is: ", marks, question);
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_question_marks", {
          method: "POST",
          question_response_id: question.question_response_id,
          feedback: parseFloat(marks).toFixed(2),
          quiz_response_id: quiz_response_id,
          quiz_total_marks: props.quiz_total_marks,
        })

        .then((response) => {
          console.log(response);
          props.getQuizReviewData();
          // question.score_edited_status = 1;
          // question.score = marks
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validation = (value, question) => {
    console.log("VALIDATION CALLED", value, question.question_marks);

    // Check if value contains any of the excluded characters

    if (value > question.question_marks) {
      return false;
    } else if (value.length === 0) {
      return false;
    } else if (value < 0) {
      return false;
    } else if (/[a-zA-Z/*+-]/.test(value)) {
      // Assuming this condition should return false
      return false;
    } else {
      return true;
    }
  };

  const handleCiruclarIndicatar = (quiz, index) => {
    if (quiz.feedback !== null) {
    }
  };
  function stripHTMLTags(str) {
    return str.replace(/<p>/g, "");
  }
  const updateRecheckData = (updatedQuestion) => {
    setSelectedQuizQuestion((prevQuiz) => prevQuiz.map((question, index) => (index === selectedQuestion ? updatedQuestion : question)));
  };
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Define mobile screen width (e.g., 768px or adjust accordingly)

      if (location.pathname === "/quiz-detail-review") {
        if (auth_type === "3") {
          if (open === true) {
            document.getElementById("moving").style.marginLeft = isMobile ? "10px" : "0px";
          } else {
            document.getElementById("moving").style.marginLeft = isMobile ? "10px" : "380px";
          }
        }
      } else {
        const formDiv = document.getElementsByClassName("form-div2")[0];
        if (open === true) {
          formDiv.style.justifyContent = isMobile ? "left" : "left"; // Mobile and non-mobile behavior
        } else {
          formDiv.style.justifyContent = isMobile ? "left" : "center";
        }
      }
    };

    handleResize(); // Call the function on component mount to apply the styles initially
    window.addEventListener("resize", handleResize); // Add event listener to adjust styles on window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup the event listener on unmount
  }, [open]);
  return (
    console.log("SPINNER VISIBLITY: ", spinnerVisibility),
    (
      <div>
        <RecheckSidePanel
          open={open}
          closePanel={handleClose}
          selectedQuestion={selectedQuestion}
          totalQuestions={totalQuestions}
          selectedQuizQuestion={selectedQuizQuestion}
          thumbs_up_down={thumbs_up_down}
          setThumbs_up_down={setThumbs_up_down}
          setQuiz_performance={props.setQuiz_performance}
          quiz_performance={props.quiz_performance}
        />
        <div>
          {quizData?.map((quiz, index) => (
            <div className="quiz-item !overflow-visible" key={index}>
              {/* {spinnerVisibility !== undefined && spinnerVisibility[index] && (
                <LoadingSpinner />
              )} */}
              <div className="flex mb-[10px]">
                <div
                  className="ml-[0px]"
                  style={{
                    width: spinnerVisibility !== undefined && spinnerVisibility[index] ? "400%" : "100%",
                  }}
                >
                  <div className=" sm:hidden md:block w-[97%]">
                    <div className=" sm:ml-[0px]" style={{ width: "100%" }}>
                      <p
                        className="answer-paragraph md:whitespace-break-spaces "
                        style={{
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `<div><span class="question" style=" color: #212529"><b>Question</b>&nbsp;${index + 1}:</span> ${stripHTMLTags(
                              JSON.parse(quiz.quiz_question).question.split("##code")[0]
                            )}</div>`,
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>

                {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                  <ShimmerEffect />
                ) : (
                  <div
                    className="teepee-label justify-end"
                    style={{
                      display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                    }}
                  >
                    <div className="ml-2 question-score">
                      <div style={{ display: "flex" }}>
                        <div>
                          <>
                            <div
                              style={{
                                display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                              }}
                            >
                              {quiz.score}
                            </div>
                          </>
                        </div>
                        <div>{"/" + quiz.question_marks}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {JSON.parse(quiz.quiz_question).image_file_name !== null && (
                <div className="flex justify-center mb-[20px] mt-[20px]">
                  <img
                    alt=""
                    className=""
                    src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + JSON.parse(quiz.quiz_question).image_file_name}
                  ></img>
                </div>
              )}
              {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                <div className="mb-[15px]">
                  <ShimmerEffect />
                </div>
              ) : (
                <div className="flex mb-[15px]">
                  <p className="answer-paragraph overflow-anywhere w-full">
                    <b>Student Answer: </b>
                    <>
                      {props.is_code === "0" && JSON.parse(quiz.quiz_question).have_code === 0 ? (
                        `${quiz.student_response}`
                      ) : (
                        <CodeEditor
                          language="javascript" // Replace with the language you want or leave empty for generic text
                          theme="monokai"
                          id={"" + index}
                          readOnly={props.readOnly}
                          name="code"
                          // onChange={(newValue) => onChange(index, newValue)}
                          value={quiz.student_response} // Pass the current value for controlled input
                        />
                      )}
                    </>
                  </p>
                </div>
              )}

              {/* <div>
            <div className="mt-2 mb-3 question">
              Student Answer: {quiz.student_response}
            </div>
          </div> */}
              {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                <ShimmerEffect />
              ) : (
                <div className="mb-[30px]">
                  <div className="flex quiz-review mb-[5px]">
                    <p className="answer-paragraph">
                      <div>
                        <div
                          style={{
                            display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                          }}
                        >
                          <b>Teepee: </b>
                          {quiz?.feedback && !quiz.feedback.startsWith("{") && !quiz.feedback.endsWith("}") ? (
                            quiz.feedback
                          ) : (
                            <FeedbackDisplay feedback={JSON.parse(quiz.feedback)} />
                          )}{" "}
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-wrap" style={{ fontFamily: "Roboto" }}>
                      <p className="text-[11px] leading-[16px] feedback_text" style={{ display: quiz.recheck_status === 1 ? "none" : "" }}>
                        ⚠️ Not happy with this result?
                        <span className="ml-1 text-[#1061F5] underline cursor-pointer" onClick={() => handleOpen(index, quizData)}>
                          [Request AI Recheck]
                        </span>
                      </p>
                      <p className="text-[11px] leading-[16px] feedback_text" style={{ display: quiz.recheck_status === 0 ? "none" : "" }}>
                        💡 Rechecked on{" "}
                        {new Date(quiz.time).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                        .
                        <span className="ml-1 text-[#1061F5] underline cursor-pointer" onClick={() => handleOpen(index, quizData)}>
                          [View History]
                        </span>
                      </p>
                    </div>
                    <div className="text-wrap">
                      {props.viewThumbsAction && <LikeDislike show_teacher_feedback={show_teacher_feedback} quiz={quiz} />}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="pb-[40px] mt-[50px]"
                style={{
                  display: JSON.parse(quiz.quiz_question).answer === null ? "none" : "block",
                  marginTop: spinnerVisibility !== undefined && spinnerVisibility[index] && "15px",
                }}
              >
                {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                  <ShimmerEffect />
                ) : props.isAnswerRubric === 1 ? (
                  <></>
                ) : (
                  <CustomizedAccordions title="Actual Answer" actualAnswer={JSON.parse(quiz.quiz_question).answer}></CustomizedAccordions>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          className="mt-[10px] mb-[10px]"
          style={{
            display: quizData.length === 0 ? "none" : "block",
          }}
        ></div>
      </div>
    )
  );
}

export default QuizReviewCard;
