import React from "react";
import ShareQuiz from "../../images/share_quiz.svg";

const ShareQuizDialog = ({ onClose, secretKey, acknowledgePopup, setAcknowledgePopup }) => {
  const handleCopy = () => {
    // Handle copy to clipboard functionality
    setAcknowledgePopup(true);
    navigator.clipboard.writeText(document.getElementById("quiz-link").value);
    setTimeout(() => {
      setAcknowledgePopup(false);
    }, 2000);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-lg relative">
        {/* Close Button */}
        <button onClick={onClose} className="absolute top-3 right-3 p-1 hover:bg-gray-100 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>

        <div className=" p-3 md:p-10">
          {/* Icon */}
          <div className="flex justify-center mb-6">
            <div className=" bg-purple-100 rounded-full flex items-center justify-center">
              <img src={ShareQuiz} />
            </div>
          </div>

          {/* Title */}
          <h2 className="text-lg font-semibold text-center mb-6">Provide Students with This Link</h2>

          {/* Link Input Group */}
          <div className="flex gap-2">
            <input
              id="quiz-link"
              type="text"
              value={process.env.REACT_APP_REST_API_CLIENT_URL + "/quiz?id=" + secretKey}
              readOnly
              className="flex-1 px-3 py-2 bg-gray-100 border border-gray-200 rounded-md focus:outline-none text-gray-600 w-[20%]"
            />
            <button
              onClick={handleCopy}
              className="px-4 py-2 bg-[#674FA4] text-white rounded-md hover:bg-purple-700 transition-colors duration-200 font-medium"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareQuizDialog;
