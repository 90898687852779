import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Image } from "@react-pdf/renderer";

// Register a font (if you need a custom font)
// Font.register({ family: 'Open Sans', src: 'path/to/font.ttf' });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  detailText: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  questionBlock: {
    marginBottom: 10,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Helvetica",
  },
  answer: {
    marginTop: 8,
    marginBottom: 4,
  },
  subHeading: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
  normalText: {
    fontSize: 10,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
});

// Create Document Component
function QuizReviewDocumentMinimock({ mock_exam, quizData, display_rubric, student_name, marks_achieved, percentage, quiz_total_marks }) {
  let q_index = 0;

  const getQuizResponseByQuestionContextId = (question_context_id, index) => {
    let question_answers_array = [];
    for (let quiz_response in quizData) {
      let quiz_question = JSON.parse(quizData[quiz_response].quiz_question);
      if (quiz_question.question_context_id === question_context_id) {
        question_answers_array.push(quizData[quiz_response]);
      }
    }
    return question_answers_array[index];
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Detail Review</Text>
          <View style={styles.detailRow}>
            <Text style={styles.detailText}>Student Name: {student_name}</Text>
            <Text style={styles.detailText}>Marks Achieved: {marks_achieved + "/" + quiz_total_marks}</Text>
          </View>
        </View>

        {JSON.parse(mock_exam.question_context_list)?.map((question_context, index) => (
          <View key={index} style={styles.questionBlock}>
            <Text style={styles.questionTitle}>
              Question {index + 1}:{question_context.question_context_name.replace(/<\/?[^>]+(>|$)/g, "")}
            </Text>
            {JSON.parse(question_context.predefined_question_answer_list)?.map((predefined_question_answer, index) => (
              <View wrap={true}>
                <Text style={styles.questionTitle}>
                  ({String.fromCharCode(q_index++ + "A".charCodeAt(0)).toLowerCase()}){" "}
                  {predefined_question_answer.question.replace(/<\/?[^>]+(>|$)/g, "")}{" "}
                  {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                    getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).score +
                      "/" +
                      getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).question_marks}
                </Text>
                <View style={styles.answer}>
                  <Text style={styles.subHeading}>Student Answer:</Text>
                  <View
                    style={{
                      backgroundColor: "#EAD1DC",
                      border: "1px solid black", // This line adds a border
                      padding: 10, // Optional: to ensure content isn't too close to the border
                    }}
                  >
                    <Text style={styles.normalText}>
                      {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                        getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).student_response}
                    </Text>
                  </View>
                </View>

                <View style={styles.answer}>
                  <Text style={styles.subHeading}>Teepee:</Text>
                  <View style={{ backgroundColor: "#EAD1DC" }}>
                    <View
                      style={{
                        backgroundColor: "#EAD1DC",
                        border: "1px solid black", // This line adds a border
                        padding: 10, // Optional: to ensure content isn't too close to the border
                      }}
                    >
                      <Text style={styles.normalText}>
                        {" "}
                        {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                          getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).feedback}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.answer}>
                  <Text style={styles.subHeading}>Actual Answer:</Text>
                  <Text style={styles.normalText}>{predefined_question_answer.answer}</Text>
                  {display_rubric === true && localStorage.getItem("user_type") !== "3" && (
                    <View>
                      <Text style={styles.normalText}>Rubric: </Text>
                      <Text style={styles.normalText}>{predefined_question_answer.rubric}</Text>
                    </View>
                  )}
                </View>
              </View>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
}

export default QuizReviewDocumentMinimock;
