import React from "react";

function StudentNotEligibleForQuiz({ quizData }) {
  return (
    <div className="form-div2">
      <div className="w-[800px]">
        <div>
          <div className="w-full">
            <p
              style={{
                display: quizData.length === 0 ? "" : "none",
                marginBottom: "0px",
                fontFamily: "Roboto",
                fontSize: "24px",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              Hey there! It looks like you've already filled out this form.
              <br />
              Unfortunately, you can only submit it once.
            </p>
            <p
              style={{
                display: quizData.length === 0 ? "" : "none",
                marginBottom: "0px",
                fontFamily: "Roboto",
                fontSize: "24px",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              If you think there's been a mistake, try reaching out to your teacher.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentNotEligibleForQuiz;
