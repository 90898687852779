import { Route, Routes } from "react-router-dom";
import "./App.css";
import TopicSelection from "./pages/TopicSelection";
import LandingPage from "./pages/LandingPage";
import GenerateQuiz from "./pages/GenerateQuiz";
import AddDetails from "./pages/Quiz";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/topic_selection" element={<TopicSelection />} />
      <Route path="/quiz_generation" element={<GenerateQuiz />} />
      <Route path="/quiz" element={<AddDetails />} />
    </Routes>
  );
}

export default App;
