import React from "react";
import Lottie from "lottie-react";
import animationData from "../../images/quiz.json";

const QuizGenerationLoading = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="text-center">
        <div role="status" className="flex justify-center">
          <Lottie animationData={animationData} loop={true} autoplay={true} style={{ width: "30%" }} />
        </div>
        <br />
        <div>
          Please wait while your <b>Topic-Focused Practice Exam</b> is being prepared...
        </div>
      </div>
    </div>
  );
};

export default QuizGenerationLoading;
