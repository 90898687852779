import React from "react";
import MonacoEditor from "@monaco-editor/react";

function CodeEditor({ language = "javascript", theme = "vs-dark", onChange, value = "", readOnly }) {
  return (
    <MonacoEditor
      height="300px"
      width="100%"
      language="python"
      theme="vs-dark"
      value={value}
      options={{
        fontSize: 16,
        minimap: { enabled: false },
        lineNumbers: "on",
        scrollBeyondLastLine: true,
        automaticLayout: true,
        readOnly: readOnly,
      }}
      onChange={readOnly ? undefined : (newValue) => onChange && onChange(newValue)}
    />
  );
}

export default CodeEditor;
