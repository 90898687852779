import React from "react";

function Footer() {
  return (
    <footer className="flex bg-[#0A1C38] text-white py-3">
      <div className="max-w-[100rem] mx-auto px-6">
        {/* <div>
      <img src={TeeoeeFooterLogo} alt="logo" />
    </div> */}
        <p className="text-sm text-gray-400 mb-0">
          Powered by
          <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600 ml-1">
            Teepee.ai
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
