import React, { useEffect, useState } from "react";
import TeepeeLogo from "../images/logo/white-logo.svg";
import TeeoeeFooterLogo from "../images/Teepie01 1.svg";
// import useGetSubjects from "../Hooks/CustomHooks/LandingPage/getSubjects";
import axios from "axios";
import apiConfig from "../config/apiConfig";
import SubjectImage from "../images/subject_image.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import SecondSubjectImage from "../images/subject_image_2.svg";
import ThirdSubjectImage from "../images/subject_image_3.svg";
import HeroSectionTransitionBottom from "../images/hero_section_transition.svg";
import HeroSectionTransitionTop from "../images/hero_section_transition_top.svg";
import HowItWorksTransitionLeft from "../images/left_image.svg";
import HowItWorksTransitionRight from "../images/right_image.svg";
import YouTubeEmbed from "../Components/LandingPage.jsx/YouTubeEmbed";

const HowItWorksStep = ({ number, title, description, isRight = false }) => (
  <div className={` relative ${isRight ? "flex-row-reverse" : ""}`}>
    <div className="w-8 h-8 rounded-full bg-black text-white flex items-center justify-center flex-shrink-0 relative z-10 mb-[14px]">{number}</div>
    <div className={`text-${isRight ? "right" : "left"} max-w-xs`}>
      <h3 className="font-bold text-[20px] mb-1">{title}</h3>
      <p className="text-gray-600 text-[16px]">{description}</p>
    </div>
  </div>
);

const Logo = () => (
  <div className="mb-1 pt-6 pl-20 inline-block">
    <img className="w-[56vw] md:w-[15vw] cursor-pointer" src={TeepeeLogo} alt="logo" />
    <div className="flex justify-end text-white cursor-pointer" onClick={() => window.open("https://teepee.ai", "_blank")}>
      <div className="mr-1 text-[12px] flex items-center">by</div>
      <div>Teepee.ai</div>
    </div>
  </div>
);

const SubjectCard = ({ title, bgColor, imgSrc, subject_id }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`rounded-xl h-64  overflow-hidden shadow-lg ${bgColor} text-white p-6 pb-0 cursor-pointer hover:shadow-xl transition-shadow relative group`}
      onClick={() => {
        window.sessionStorage.setItem("subject_id", subject_id);
        console.log(window.sessionStorage.getItem("subject_id"));
        navigate("/topic_selection");
      }}
    >
      <h3 className="text-xl font-semibold mb-4 text-center h-[20%]">{title}</h3>
      <div className="flex items-center justify-center overflow-hidden">
        <img
          src={imgSrc}
          alt={title}
          className="w-full h-40 object-contain rounded-lg transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>
    </div>
  );
};

const LandingPage = () => {
  const [subjects, setSubjects] = useState([]);
  const getSubject = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + apiConfig.endpoints.getSubjects,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        setSubjects(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSubject();
  }, []);

  const colors = ["bg-[rgb(44,44,44)]", "bg-[rgb(85,77,183)]", "bg-[rgb(61,153,154)]"]; // Add more colors as needed
  const images = [SubjectImage, SecondSubjectImage, ThirdSubjectImage]; // Replace these with actual image imports or paths

  return (
    <div>
      <div className="min-h-screen bg-white">
        {/* Hero Section */}
        <div className="relative">
          <div className="bg-[#362271] pb-8">
            {/* Logo */}
            <div className="mb-12">
              <Logo />
            </div>
            <div className="max-w-5xl mx-auto p-8">
              {/* Hero Content */}
              <div className="text-center mx-auto">
                <h1 className="text-[24px] md:text-[40px] font-bold text-gray-800 mb-[12px] text-white">
                  Effortlessly Conduct Free,
                  <span className="text-[#FFC727]"> Topic-Based Mock</span> Tests with Instant AI Marking and Feedback
                </h1>
                <p className="text-[#FFFFFF] text-[14px] md:text-[24px] text-white relative z-1">
                  Deliver instant, accurate feedback to students while gaining actionable insights with detailed AI-generated reports.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:block absolute bottom-0">
            <img src={HeroSectionTransitionBottom} />
          </div>
          <div className="hidden md:block absolute right-0 top-0">
            <img src={HeroSectionTransitionTop} />
          </div>
        </div>
        <div className="pt-10 flex justify-center">
          <div className="w-[90vw] md:w-[60vw]">
            <YouTubeEmbed videoId={"tYVeVqYlgjw"} />
          </div>
        </div>
        {/* How It Works Section */}
        <div className="relative">
          <div className="py-12 max-w-6xl mx-auto px-8 relative">
            <h2 className="text-3xl font-bold text-center mb-10">How It Works</h2>

            <div className="max-w-2xl mx-auto relative">
              {/* Vertical line in the middle */}
              <div className="absolute left-1/2 top-0 w-[1px] h-full bg-gray-200 -translate-x-1/2" />

              <div className="space-y-4">
                {/* Steps with alternating alignment */}
                <div className="grid grid-cols-2 gap-4">
                  <div></div>
                  <HowItWorksStep number={1} title="Select Subject & Topic" description="Pick a subject and topic to test." isRight={true} />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <HowItWorksStep number={2} title="Generate & Share" description="Create a mock test and share it with your students." />
                  <div></div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div></div>
                  <HowItWorksStep
                    number={3}
                    title="Instant Student Feedback"
                    description="Students get instant feedback on their answers."
                    isRight={true}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <HowItWorksStep number={4} title="Detailed Class Insights" description="Teachers receive AI-generated performance reports." />
                  <div></div>
                </div>
              </div>
            </div>

            {/* Decorative Background Elements */}
            <div className="absolute left-0 top-1/2 -translate-y-1/2 -z-10">
              <div className="w-48 h-48 bg-gray-50 rounded-full blur-3xl opacity-50"></div>
            </div>
            <div className="absolute right-0 top-1/4 -z-10">
              <div className="w-64 h-64 bg-purple-50 rounded-full blur-3xl opacity-50"></div>
            </div>
          </div>
          <div className="hidden md:block absolute left-0 top-20">
            <img className="md:w-[70%]" src={HowItWorksTransitionLeft} />
          </div>
          <div className="hidden md:block absolute right-0 top-20">
            <img className="md:w-[70%] float-right" src={HowItWorksTransitionRight} />
          </div>
        </div>

        {/* Subject Selection Section */}
        <div className="max-w-8xl mx-auto md:px-24 pb-24 ">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Choose Your Subject</h2>
            <p className="text-gray-600 text-lg">
              Select a subject to create topic-focused practice exams and help your students revise effectively.
            </p>
          </div>
          <div className={`${subjects.length === 1 ? "flex justify-center" : ""}`}>
            <div
              className={`grid grid-cols-1 px-[5%] ${
                subjects.length === 1 ? "md:grid-cols-1" : subjects.length === 2 ? "md:grid-cols-2" : "md:grid-cols-3"
              } gap-8`}
            >
              {subjects.map((subject, index) => {
                const bgColor = colors[index % colors.length]; // Cycle through colors
                const imgSrc = images[index % images.length]; // Cycle through images
                return (
                  <div key={index}>
                    <SubjectCard title={subject.subject_name} bgColor={bgColor} imgSrc={imgSrc} subject_id={subject.subject_id} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
